/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
    position: relative;
    overflow: hidden;
	margin-bottom: 0px;
	.swiper-slide {
        max-height: 680px;
		overflow: hidden;
		position: relative;
		outline: none;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.2);
        }
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.slider__title {
            width: 1170px;
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
            font-family: $esqadero;
            font-size: 80px;
			text-align: center;
			color: #fff;
            margin: 0 auto;
            transform: translateY(-50%);
		    z-index: 1;
        }
	}
	.swiper-pagination {
        bottom: 50px;
        z-index: 1;
		.swiper-pagination-bullet {
            width: 30px;
            height: 7px;
            background-color: #fcd900;
            border-radius: 0px;
            opacity: 1;
            margin: 0 5px;
            cursor: pointer;
            transition: 300ms;
            &:hover, &.swiper-pagination-bullet-active {
                background-color: #ffffff;
            }
        }
	}
    @media screen and (max-width: 1680px) {
        .swiper-slide {
            .slider__title {
                font-size: 70px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .swiper-slide {
            .slider__title {
                width: auto;
                font-size: 60px;
                left: 25px;
                right: 25px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .swiper-slide {
            .slider__title {
                font-size: 50px;
                line-height: 1;
            }
        }
        .swiper-pagination {
            bottom: 30px;
        }
    }
    @media screen and (max-width: 1024px) {
        .swiper-slide {
            .slider__title { 
                font-size: 40px;
            }
        }
        .swiper-pagination {
            bottom: 20px;
            .swiper-pagination-bullet {
                width: 25px;
                height: 5px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .swiper-slide {
            .slider__title {
                font-size: 30px;
            }
        }
        .swiper-pagination {
            bottom: 15px;
        }
    }
    @media screen and (max-width: 600px) {
        .swiper-slide {
            .slider__title {
                font-size: 20px;
            }
        }
        .swiper-pagination {
            bottom: 10px;
        }
    }
}
/* main slider */

/* main catalog */
.cat__block {
    display: flex;
    flex-wrap: wrap;
    padding: 7.5px 5px;
    .cat__col {
        position: relative;
        height: 340px;
        width: calc(33.33% - 15px);
        margin: 7.5px 7.5px;
        overflow: hidden;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            transition: 300ms;
        }
        .cat__title {
            display: block;
            max-width: calc(100% - 60px);
            background-color: #fff;
            font-family: $esqadero;
            font-size: 20px;
            color: #414141;
            position: absolute;
            left: 30px;
            bottom: 30px;
            padding: 10px 20px;
            transition: 300ms;
            z-index: 1;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
            .cat__title {
                background-color: #fcd900;
                color: #000000;
            }
        }
        &.half {
            width: calc(50% - 15px);
        }
    }
    @media screen and (max-width: 1680px) {
        .cat__col {
            height: 280px;
        }
    }
    @media screen and (max-width: 1440px) {
        .cat__col {
            height: 250px;
            .cat__title {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1366px) {
        .cat__col {
            height: 240px;
            .cat__title {
                max-width: calc(100% - 30px);
                font-size: 16px;
                left: 15px;
                bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .cat__col {
            height: 180px;
            .cat__title {
                font-size: 14px;
                padding: 10px 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cat__col {
            height: 150px;
            .cat__title {
                font-size: 12px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .cat__col {
            width: calc(50% - 15px);
        }
    }
    @media screen and (max-width: 400px) {
        .cat__col {
            height: auto;
            width: 100%;
            img {
                height: auto;
            }
            &.half {
                width: 100%;
                height: auto;
            }
        }
    }
}
/* main catalog */

/* main project */
.project {
    display: flex;
    align-items: center;
    padding: 105px 0;
    .left {
        display: flex;
        width: 65%;
        flex-wrap: wrap;
        padding: 0 12.5px 0 0;
    }
    .right {
        width: 35%;
        padding: 12.5px 220px 12.5px 45px;
    }
    .project__block {
        width: calc(33.33% - 25px);
        height: 360px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin: 12.5px;
        &:after {
            content: '';
            width: 30px;
            height: 30px;
            background: url(../img/plus.png) no-repeat center center;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: 0 auto;
            opacity: 0;
            transition: 300ms;
            opacity: 0;
            z-index: 1;
        }
        &:before {
            content: '';
            height: 0;
            font-family: 'fontAwesome';
            background-color: rgba(242, 205, 0, .9);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            transition: 300ms;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            transition: 350ms;
        }
        .project__title {
            background-color: #fff;
            font-family: $medium;
            color: #414141;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -100%;
            padding: 15px 0;
            transition: 300ms;
            z-index: 1;
            p {
                margin: 0;
            }
        }
        &:hover {
            &:before {
                height: 100%;
                opacity: 1;
            }
            &:after {
                opacity: 1;
            }
            img {
                transform: scale(1.1);
            }
            .project__title {
                bottom: 0;
            }
        } 
    }
    .btn {
        width: 180px!important;
        font-family: $bold!important;
        text-transform: inherit;
        padding: 10px 20px!important;
        margin-top: 40px;
    } 

    &.project__slider {
        display: block;
        width: calc(100% - 100px);
        overflow: hidden;
        margin: 0 auto;
        .project__block {
            margin: 0;
            .project__title {
                padding: 15px;
            }
        }
        .swiper-button-next, 
        .swiper-button-prev {
            display: flex;
            background: none;
            top: 58%;
            margin-top: 0;
            align-items: center;
            justify-content: center;
            line-height: 1;
            &:after {
                content: '\f104';
                font-family: 'fontAwesome';
                font-size: 60px;
                line-height: 30px;
            }
        }
        .swiper-button-next {
            &:after {
                transform: rotate(-180deg);
            }
        }
    }
    
    @media screen and (max-width: 1680px) {
        .project__block {
            height: 310px;
        }
        .right {
            padding: 10px 150px 10px 30px;
        }
    }

    @media screen and (max-width: 1440px) {
        padding: 75px 0;
        .project__block {
            height: 270px;
        }
        .right {
            padding: 10px 100px 10px 30px;
        }
    }

    @media screen and (max-width: 1366px) {
        .project__block {
            height: 250px;
        }
    }

    @media screen and (max-width: 1200px) {
        padding: 50px 0;
        .project__block {
            width: calc(50% - 25px);
            height: 300px;
        }
        .left {
            padding-right: 0;
        }
        .right {
            padding: 10px 50px 10px 20px;
        }
        .btn {
            width: 150px!important;
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 30px 0;
        .btn {
            margin-top: 20px;
        }
        .project__block {
            height: 220px;
            &:before {
                opacity: 0;
                height: 100%;
            }
            &:after {
                display: none;
            }
            .project__title {
                bottom: 0;
            }
            &:hover {
                &:before {
                    opacity: 0;
                }
            }
        }
        &.project__slider {
            width: calc(100% - 70px);
            .swiper-button-next, 
            .swiper-button-prev {
                &:after {
                    font-size: 45px;
                }
            }
            .swiper-button-next {
                right: 3px;
            }
            .swiper-button-prev {
                left: 3px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 20px 0;
        flex-wrap: wrap;
        .left, .right {
            width: 100%;
            padding: 0;
        }
        .left {
            order: 2;
        }
        .right {
            order: 1;
            padding: 10px 15px;
        }
    }
    @media screen and (max-width: 600px) {
        padding: 0px;
    }
    @media screen and (max-width: 480px) {
        .project__block {
            height: 200px;
        }
    }
    @media screen and (max-width: 400px) {
        .project__block {
            width: 100%;
        }
    }
}
/* main project */

/* main about */
.about {
    display: flex;
    align-items: stretch;
    .left,.right {
        width: 50%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        padding: 115px 50px 95px 155px;
    }
    .right {
        display: flex;
    }
    h1 {
        text-align: left;
    }
    @media screen and (max-width: 1280px) {
        .left {
            padding: 75px 35px 50px 100px;
        }
    }
    @media screen and (max-width: 1024px) {
        .left {
            padding: 30px 25px 20px 35px;
        }
    }
    @media screen and (max-width: 768px) {
        .left {
            padding-left: 15px;
        }
    }
    @media screen and (max-width: 600px) {
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 20px 15px;
        .left, .right {
            width: 100%;
        }
        .left {
            padding: 0 0 10px 0;
        }
    }
}
/* main about */

/* main news */
.news {
    h2 {
        text-align: center;
    }
}
.news__block {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    .news__col {
        width: calc(50% - 45px);
        .news__img {
            height: 280px;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                transition: 300ms;
            }
        }
        p {
            margin: 15px 0;
        }
        .date {
            display: block;
            color: #878787;
        }
        &:hover {
            .news__img {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    @media screen and (max-width: 1680px) {
        .news__col {
            .news__img {
                height: 240px;
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .news__col {
            width: calc(50% - 25px);
            .news__img {
                height: 200px;
            }
        }
    }

    @media screen and (max-width: 1366px) {
        .news__col {
            .news__img {
                height: 195px;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .news__col {
            width: calc(50% - 10px);
            .news__img {
                height: 150px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .news__col {
            .news__img {
                height: 110px;
            }
        }
    }
    @media screen and (max-width: 400px) {
        flex-wrap: wrap;
        .news__col {
            width: 100%;
            margin-bottom: 25px;
            p {
                margin: 10px 0;
            }
            &:last-of-type {
                margin-bottom: 10px;
            }
        }
    }
}
/* main news */

/* main content */