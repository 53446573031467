/* footer */
footer {
    background-color: #222222;
    font-size: 18px;
    color: #fff;
    padding-top: 50px;
    margin-top: 75px;
    .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: calc(100% - 90px);
        padding: 0;
        margin: 0 45px;
        &.down {
            height: 80px;
            font-size: 16px;
            align-items: center;
            border-top: 1px solid #796b14;
            margin-top: 50px;
            p {
                margin: 0;
            }
        }
    }
    a {
        color: #fff;
        &:focus {
            color: #fff;
        }
        &:hover {
            color: #fcd900;
        }
    }
    ul {
        @include ul-default;
        li {
            margin: 15px 0;
            &.active {
                a {
                    color: #fcd900;
                }
            }
        }
    }
    .col--location,
    .col--phone,
    .col--email {
        &:after {
            top: 2px;
        }
    }
    .col--email:after {
        top: 3px;
    }
    h5 {
        font-family: $boldD;
        color: #fcd900;
        margin: 0 0 25px;
    }
    .footer__logo {
        display: table;
        height: 65px;
        width: 360px;
        overflow: hidden;
        position: relative;
        img {
            display: block;
            max-width: 100%;
        }
    }
    .footer__col {
        padding-left: 15px;
        &:first-of-type {
            padding-left: 0;
            padding-right: 25px;
        }
        &:nth-of-type(2) {
            margin-left: -8%;
        }
    }
    .artmedia {
        display: flex;
        align-items: center;
    }
    @media screen and (max-width: 1680px) {
        .footer__col {
            &:nth-of-type(2) {
                margin-left: -5%;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .footer__col {
            &:nth-of-type(2) {
                margin-left: 0;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .container {
            width: auto;
            padding: 0 15px;
            margin: 0;
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 16px;
        padding-top: 20px;
        margin-top: 50px;
        .container {
            &.down {
                height: 60px;
                margin-top: 10px;
            }
        }
        .footer__logo {
            padding-right: 30px;
        }
        h5 {
            margin-bottom: 15px;
        }
        ul {
            li {
                margin: 10px 0;
            }
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-top: 30px;
        .container {
            &.down {
                font-size: 14px;
            }
        }
        .col--email:after {
            top: 4px;
        }
    }
    @media screen and (max-width: 680px) {
        .container {
            flex-wrap: wrap;
        }
        .footer__logo {
            padding: 0;
            margin: 0 auto;
        }
        .footer__col {
            width: 33.33%;
            &:first-of-type {
                width: 100%;
                padding: 0;
                margin-bottom: 15px;
            }
            &:nth-of-type(2) {
                padding-left: 0;
            }
        }
    }
    @media screen and (max-width: 640px) {
        .container {
            &.down {
                height: auto;
                flex-direction: column;
                text-align: center;
                padding: 10px 15px;
            }
        }
        .artmedia {
            margin-top: 10px;
        }
    }
    @media screen and (max-width: 600px) {
        .footer__logo {
            width: 280px;
        }
    }
    @media screen and (max-width: 500px) {
        .footer__col {
            width: 100%;
            padding: 0;
            margin-bottom: 15px;
            &:last-of-type {
                margin-bottom: 10px;
            }
        }
    }
}
/* footer */