/* page content */
.page {
	.news1, .news {
		.date {
			color: #fcd900;
		}
	}
	.page_nav {
		.active {
			color: #fcd900;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #fcd900;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    p a {
        color: #fcd900;
        &:hover {
            text-decoration: underline;
        }
    }
    a.brown {
        font-family: $medium;
        color: #521800;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    h1 {
        font-size: 32px;
    }
    .pagination {
        margin-top: 15px;
        ul {
            @include ul-default;
            li {
                @include inline-block;
                margin: 0 5px 5px 0;
                &.active a {
                    font-family: $bold;
                    color: #fcd900;
                }
            }
        }
    }
	@media screen and (max-width: 1170px) {
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 991px) {
        h1 {
            font-size: 28px;
        }
    }
    @media screen and (max-width: 768px) {
        h1 {
            font-size: 24px;
        }
    }
	@media screen and (max-width: 480px) {
        h1 {
            font-size: 22px;
        }
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;   
    margin: 20px 0 55px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #bca200;
			@include inline-block;
			a {
                color: #414141;
				text-decoration: none;
                &:hover {
                    color: #bca200;
                }
			}
			&:after {
				content: '';
                width: 1px;
                height: 16px;
                background-color: #414141;
				margin: 3px 8px 0px 10px;
				color: #414141;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    @media screen and (max-width: 1200px) {
        margin: 15px 0 30px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        line-height: 1;
        margin: 10px 0 20px;
        ul {
            li {
                &:after {
                    height: 14px;
                    margin: 0px 3px 0 7px;
                }
            }
        }
    }
}
/* breadcrumbs */

/* page about */
.about__block {
    display: flex;
    margin-bottom: 30px;
    .left, .right {
        width: 50%;
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .left {
        display: flex;
        justify-content: flex-end;
        padding-right: 25px;
    }
    .about__info {
        width: 545px;
    }
    @media screen and (max-width: 1200px) {
        .left {
            padding-left: 15px;
        }
        .about__info {
            width: 100%;
        }
    }
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        .left, .right {
            width: 100%;
            padding: 0 15px;
            img {
                height: auto;
            }
        }
    }
}
.about__stiker {
    position: relative;
    background-color: #fcd900;
    color: #414141;
    padding: 20px 30px;
    margin: 30px 0;
    &:after {
        content: '';
        width: 44px;
        height: 35px;
        background: url(../img/quote.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        z-index: 0;
    }
    * {
        position: relative;
        z-index: 1;
    }
    h3 {
        font-family: $esqadero;
        font-size: 26px;
        text-transform: inherit;
        margin: 0;
    }
    @media screen and (max-width: 1200px) {
        h3 {
            font-size: 22px;
        }    
    }
    @media screen and (max-width: 1024px) {
        margin: 15px 0;
        h3 {
            font-size: 18px;
        }    
    }
    @media screen and (max-width: 768px) {
        padding: 15px 15px 15px 30px;
        line-height: 1;
        &:after {
            width: 20px;
            height: 20px;
            left: 5px;
        }
        h3 {
            font-size: 16px;
        }    
    }
    @media screen and (max-width: 600px) {
        h3 {
            font-size: 14px;
        }
    }
}
.about__list {
    display: flex;
    .check--list {
        min-width: 420px;
        padding-right: 15px;
    }
    @media screen and (max-width: 1024px) {
        .check--list {
            min-width: 0px;
        }
    }
    @media screen and (max-width: 500px) {
        flex-wrap: wrap;
        .check--list {
            width: 100%;
            padding: 0;
        }
    }
}

/* page about */

/* page catalog */
.cat--inside {
    display: flex;
    align-items: flex-start;
    .left  {
        width: calc(100% - 360px);
        padding-right: 75px;
    }
    @media screen and (max-width: 1024px) {
        .left {
            width: calc(100% - 300px);
            padding-right: 20px;
        }
    }
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        .left {
            width: 100%;
            padding: 0;
        }   
    }
}
.cat--inside__slider {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 30px 0;
    &.arrow .swiper-nav {
        top: 42%;
    }
    @media screen and (max-width: 1024px) {
        width: 100%;
        .cat--inside__col {
            .cat--inside__img {
                height: 220px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin: 20px 0;
        .cat--inside__col {
            .cat--inside__title {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cat--inside__col {
            .cat--inside__img {
                height: 180px;
            }
        }
    }
}
.cat--inside__col {
    line-height: 1.1;
    .cat--inside__img {
        height: 280px;
        position: relative;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 7px solid #e1e1e1;
            opacity: 1;
            transition: 300ms;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    .cat--inside__title {
        display: block;
        font-family: $boldD;
        font-size: 18px;
        color: #414141;
        text-align: center;
        margin: 15px 0;
    }
    &:hover {
        .cat--inside__img:after {
            border: 7px solid #fcd900;
        }
    }
    @media screen and (max-width: 1024px) {
        .cat--inside__img {
            height: 200px;
        }
    }
    @media screen and (max-width: 768px) {
        .cat--inside__img {
            height: 150px;
        }
    }
    @media screen and (max-width: 400px) {
        .cat--inside__img {
            height: auto;
        }
    }
}
.arrow {
    position: relative;
    .swiper-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 80px;
        background: none;
        background-color: #f3f3f3;
        top: 50%;
        transform: translateY(-50%);
        transition: 300ms;
        margin: 0;
        z-index: 1;
        &:after {
            content: '';
            font-family: 'fontAwesome';
            font-size: 30px;
            color: #414141;
        }
        &.swiper-button-prev {
            left: 0;
            &:after {
                content: '\f104';
            }
        }
        &.swiper-button-next {
            right: 0;
            &:after {
                content: '\f105';
            }
        }
        &.swiper-button-disabled {
            opacity: 0;
        }
        &:hover {
            background-color: #fcd900;
        }
    }
    @media screen and (max-width: 768px) {
        .swiper-nav {
            height: 60px;
        }
    }
    @media screen and (max-width: 600px) {
        .swiper-nav {
            height: 50px;
            width: 25px;
        }
    }
}
.sidebar {
    width: 360px;
    font-family: $esqadero;
    user-select: none;
    ul {
        @include ul-default;
        li {
            position: relative;
            a {
                display: table;
                font-size: 16px;
                color: #414141;
            }
            &.open > .submenu__btn {
                transform: rotate(-180deg);
            }
        }
        ul {
            display: none;
            li {
                &.active > a {
                    color: #bca200;
                }
                a {
                    color: #696969;
                    &:hover {
                        color: #bca200;
                    }
                }
            }
        }
    }
    > ul > li {
        margin-bottom: 5px;
        &.active > a {
            background-color: #fcd900;
            .submenu__btn {
                color: #414141;
            }
        }
        >  a {
            display: flex;
            min-height: 55px;
            background-color: #565653;
            color: #fff;
            align-items: center;
            font-size: 16px;
            padding: 5px 20px 5px 45px;
        }
        > ul {
            background-color: #f3f3f3;
            padding: 5px 0;
            margin-top: 5px;
            > li {
                margin: 8px 15px 8px 35px;
                > a {
                    padding: 0 20px;
                }
                .submenu__btn {
                    top: 0;
                    left: 0;
                    color: #000000;
                }
                > ul {
                    padding: 2px 0;
                    margin: 0 0 0 30px;
                    > li {
                        padding: 4px 0;
                        a {
                            color: #696969;
                        }
                    }
                }
            }
        }
    }
    .submenu__btn {
        position: absolute;
        left: 20px;
        top: 18px;
        font-family: 'fontAwesome';
        font-size: 18px;
        color: #fff;
        cursor: pointer;
        transition: 300ms;
        &:after {
            content: '\f107';
        }
    }
    @media screen and (max-width: 1024px) {
        width: 300px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        ul li a {
            font-size: 14px;
        }
        > ul > li {
            > a {
                font-size: 14px;
                padding: 5px 10px 5px 30px;
            }
            > .submenu__btn {
                left: 10px;
            }
            > ul > li {
                margin: 5px 10px 5px 10px;
                > a {
                    padding: 0 0 0 20px;
                }
                ul {
                    margin-left: 30px;
                    li {
                        a {
                            padding: 0;
                        }
                    }
                }
            }
        }
        .submenu__btn {
            display: flex;
            width: 15px;
            height: 15px;
            align-items: center;
            justify-content: center;
            top: 20px;
        }
    }
}

.cat--inside__for {
    width: 100%;
    overflow: hidden;
    .swiper-slide {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 7px solid #e1e1e1;
        }
        img {
            display: block;
            width: 100%;
        }
    }
    @media screen and (max-width: 1024px) {
        .swiper-slide {
            height: 430px;
        }
    }
    @media screen and (max-width: 768px) {
        .swiper-slide {
            height: 350px;
        }
    }
    @media screen and (max-width: 600px) {
        .swiper-slide {
            height: 300px;
        }
    }
    @media screen and (max-width: 480px) {
        .swiper-slide {
            height: 250px;
        }
    }
    @media screen and (max-width: 380px) {
        .swiper-slide {
            height: 220px;
        }
    }
}
.cat--inside__nav {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 30px;
    .swiper-slide {
        height: 120px;
        position: relative;
        cursor: pointer;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 7px solid #e1e1e1;
            transition: 300ms;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        &:hover, &.swiper-slide-thumb-active {
            &:after {
                border: 7px solid #fcd900;
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin: 10px 0 20px;
    }
    @media screen and (max-width: 400px) {
        .swiper-slide {
            height: 90px;
        }
    }
}

.cat--inside__block {
    display: flex;
    flex-wrap: wrap;
    margin: 40px -15px 0;
    .cat--inside__col {
        width: calc(33.33% - 30px);
        margin: 0 15px;
    }
    @media screen and (max-width: 768px) {
        margin: 20px -10px 0;
        .cat--inside__col {
            width: calc(33.33% - 20px);
            margin: 0 10px;
        }
    }
    @media screen and (max-width: 600px) {
        margin: 20px -10px 0;
        .cat--inside__col {
            width: calc(50% - 20px);
            margin: 0 10px;
        }
    }
    @media screen and (max-width: 400px) {
        margin: 20px 0px 0;
        .cat--inside__col {
            width: 100%;
            margin: 10px 0;
        }
    }
}
.cat--unit {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .left {
        display: flex;
        width: 550px;
        flex-wrap: wrap;
    }
    .right {
        display: flex;
        flex-direction: column;
        width: calc(100% - 550px);
        padding-left: 45px;
    }
    h1 {
        font-size: 30px;
        text-align: left;
        line-height: 1.2;
    }
    h2 {
        font-family: $bold;
        font-size: 28px;
    }
    .btn {
        font-family: $boldE!important;
        text-transform: uppercase;
        margin-top: 45px;
    }
    @media screen and (max-width: 1280px) {
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 24px;
        }
        .left {
            width: 450px;
        }
        .right {
            width: calc(100% - 450px);
        }
        .btn {
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        h1 {
            font-size: 20px;
        }
        h2 {
            font-size: 20px;
        }
        .left {
            width: 350px;
        }
        .right {
            width: calc(100% - 350px);
            padding-left: 20px;
        }
    }
    @media screen and (max-width: 768px) {
        h1 {
            font-size: 18px;
        }
    }
    @media screen and (max-width: 680px) {
        .left, .right {
            width: 100%;
        }
        .right {
            padding-left: 0;
            margin-bottom: 25px;
        }
    }
}

.cat--inside__unit--for {
    width: 100%;
    overflow: hidden;
    .swiper-slide {
        position: relative;
        height: 430px;
        padding: 10px;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 7px solid #e1e1e1;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }
    @media screen and (max-width: 1280px) {
        .swiper-slide {
            height: 380px;
        }
    }
    @media screen and (max-width: 1024px) {
        .swiper-slide {
            height: 300px;
        }
    }
    @media screen and (max-width: 400px) {
        .swiper-slide {
            height: 250px;
        }
    }
}
.cat--inside__unit--nav {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 30px;
    .swiper-slide {
        width: 200px;
        height: 140px;
        position: relative;
        cursor: pointer;
        padding: 10px;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 7px solid #e1e1e1;
            transition: 300ms;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
        &:hover, &.swiper-slide-thumb-active {
            &:after {
                border: 7px solid #fcd900;
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .swiper-slide {
            width: 160px;
            height: 120px;
        }
    }
    @media screen and (max-width: 1024px) {
        .swiper-slide {
            width: 124px;
            height: 100px;
        }
    }
    @media screen and (max-width: 400px) {
        .swiper-slide {
            width: 100px;
            height: 80px;
        }
    }
}

.cat--unit__table {
    font-family: $boldD;
    color: #828282;
    tr {
        td {
            padding: 5px 15px 5px 0;
            border-bottom: 1px solid #e1e1e1;
            border-right: 1px solid #e1e1e1;
            &:nth-of-type(2) {
                border-right: none;
                padding-left: 20px;
            }
            strong {
                color: #414141;
            }
        }
        &:first-of-type {
            td {
                padding-top: 0;
            }
        }
        &:last-of-type {
            td {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}
/* page catalog */

/* page contacts */
.cts {
    .cts__row {
        display: flex;
        align-items: center;
        .cts__block {
            display: flex;
            width: 50%;
            justify-content: flex-end;
            .cts__info {
                width: 440px;
                background-color: #fff;
                padding: 40px 30px;
                box-shadow: 0px 0px 25px #ededed;
                margin-right: 130px;
                ul {
                    @include ul-default;
                    li {
                        margin: 15px 0;
                        &:after {
                            top: 2px;
                        }
                        &.col--email:after {
                            top: 4px;
                        }
                    }
                }
            }
        }
        .cts__img {
            width: 50%;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        &:nth-of-type(even) {
            .cts__block {
                justify-content: flex-start;    
                order: 2;
                .cts__info {
                    margin: 0 0 0 130px;
                }
            }
            .cts__img {
                order: 1;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .cts__row {
            .cts__block {
                padding-left: 15px;
                .cts__info {
                    width: calc(100% - 15px);
                    padding: 30px 20px;
                    margin-right: 15px;
                }
            }
            &:nth-of-type(even) {
                .cts__block {
                    justify-content: flex-start;    
                    .cts__info {
                        margin: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cts__row {
            align-items: stretch;
            .cts__block {
                .cts__info {
                    width: 100%;
                    padding: 20px 15px 20px 0;
                    margin-right: 0;
                    box-shadow: none;
                    ul li {
                        margin: 10px 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 400px) {
        .cts__row {
            flex-wrap: wrap;
            align-items: flex-start;
            .cts__block, .cts__img {
                width: 100%;
                .cts__info {
                    padding: 0px;
                }
            }
            .cts__img {
                margin-bottom: 20px;
            }
            &:nth-of-type(even) {
                .cts__img {
                    order: 2;
                }
            }
        }
    }
}
.cts__form {
    padding-top: 85px;
    h2 {
        text-align: center;
    }
    .g-recaptcha {
        @include inline-block;
    }
    .btn {
        width: auto!important;
        background-color: #222222!important;
        color: #fff!important;
        float: right;
        margin-top: 10px;
        &:hover {
            background-color: #fcd900!important;
            color: #222222!important;
        }
    }
    @media screen and (max-width: 1200px) {
        padding-top: 50px;
    }
    @media screen and (max-width: 1024px) {
        padding-top: 30px;
    }
    @media screen and (max-width: 768px) {
        padding-top: 10px;
    }
    @media screen and (max-width: 460px) {
        padding-top: 30px;
        padding-bottom: 50px;
        .g-recaptcha {
            display: table;
            transform: scale(1);
            transform-origin: 50% 50%;
            margin: 0 auto;
            > div {
                margin: 0 auto;
            }
        }
        .btn {
            display: table;
            width: 100%!important;
        }
    }
    @media screen and (max-width: 360px) {
        .g-recaptcha {
            transform: scale(0.9);
            transform-origin: 40% 50%;
            margin: 0 auto;
        }
    }
}
/* page contacts */

/* modal */
// .modal {
//     .modal__block {
//         width: auto;
//         max-width: 420px;
//         border-radius: 0px;
//         margin: 0 auto;
//         h4 {
//             font-family: $bold;
//             text-align: center;
//             text-transform: uppercase;
//             padding: 0 15px;
//             margin: 0;
//         }
//     }
//     form {
//         display: block;
//         padding-bottom: 140px;
//         margin-top: 25px;
//         textarea, 
//         input[type="text"], 
//         input[type="email"], 
//         input[type="search"], 
//         input[type="password"] {
//             width: 100%;
//             background-color: #fff;
//             font-family: $regular;
//             font-size: 16px;
//             padding: 5px 15px;
//             margin-bottom: 15px;
//             box-shadow: none;
//         }
//         .g-recaptcha {
//             float: right;
//         }
//         .btn {
//             float: right;
//             margin: 15px 0 0 0;
//         }
//     }
//     @media screen and (max-width: 767px) {
//         .modal-content {
//             h4 {
//                 font-size: 16px;
//             }
//         }
//     }
//     @media screen and (max-width: 420px) {
//         .modal-dialog {
//             margin: 0 10px;
//             h4 {
//                 font-size: 16px;
//             }
//         }
//     }
//     @media screen and (max-width: 380px) {
//         .modal-content {
//             form {
//                 .g-recaptcha {
//                     transform: scale(0.85);
//                     transform-origin: 100% 50%;
//                 }
//             }
//         }
//     }
// }

.modal {
    display: none;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 420px;
        background-color: #fff;
        position: absolute;
        padding: 20px;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        transform: translateY(-50%);
        h4 {
            font-size: 20px;
            text-align: center;
            padding: 0 10px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 30px;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            width: 100%;
            min-height: 65px;
            background-color: #fff;
            border-radius: 0px;
            background-color: #fff;
            font-family: $regular;
            font-size: 18px;
            color: #414141;
            border: 1px solid #bababa;
            padding: 20px 20px;
            margin-bottom: 20px;
            box-shadow: none;
            line-height: 1;
            &::placeholder {
                color: #414141;
            }
        }
        .g-recaptcha {
            transform: scale(1);
            transform-origin: 50% 50%;
            > div {
                margin: 0 auto;
            }
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 1200px) {
        .modal__block {
            h4 {
                font-size: 18px;
            }
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 50px;
                font-size: 16px;
                padding: 14px 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                font-size: 14px;
                padding: 10px 15px;
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal__block {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
        }
    }
    @media screen and (max-width: 380px) {
        form {
            .g-recaptcha {
                transform: scale(0.85);
                transform-origin: 0% 50%;
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 100px;
    right: 105px;
    display: table;
    background-color: #fcd900;
    font-family: 'FontAwesome';
    border-radius: 0px;
    text-decoration: none!important;
    width: 70px;
    height: 70px;
    text-align: center;
    padding: 18px 5px 5px 5px;
    box-sizing: border-box;
    outline: none!important;
    z-index: 1;
    i {
        display: block;
        font-size: 36px;
        color: #000000;
        transition: 300ms;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        border: 1px solid #fcd900;
        position: absolute;
        top: 5%;
        left: 5%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
        z-index: -1;
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
    }
    &:hover i {
        transform: scale(1.1);
    }
    @media screen and (max-width: 1440px) {
        right: 50px;
        bottom: 50px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 13px;
        right: 15px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
        padding-top: 10px;
    }
}
@keyframes ring {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1.7);
    }
}


/* modal */

/* page content */