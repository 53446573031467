/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 29, 2019 */
@font-face {
    font-family: 'esqadero_ff_cy_4fregular';
    src: url('../fonts/esqaderoffcy4f-webfont.eot');
    src: url('../fonts/esqaderoffcy4f-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/esqaderoffcy4f-webfont.woff2') format('woff2'),
         url('../fonts/esqaderoffcy4f-webfont.woff') format('woff'),
         url('../fonts/esqaderoffcy4f-webfont.ttf') format('truetype'),
         url('../fonts/esqaderoffcy4f-webfont.svg#esqadero_ff_cy_4fregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'houschka_roundedbold';
    src: url('../fonts/houschka_rounded_bold-webfont.eot');
    src: url('../fonts/houschka_rounded_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/houschka_rounded_bold-webfont.woff2') format('woff2'),
         url('../fonts/houschka_rounded_bold-webfont.woff') format('woff'),
         url('../fonts/houschka_rounded_bold-webfont.ttf') format('truetype'),
         url('../fonts/houschka_rounded_bold-webfont.svg#houschka_roundedbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'houschka_roundedextrabold';
    src: url('../fonts/houschka_rounded_extra_bold-webfont.eot');
    src: url('../fonts/houschka_rounded_extra_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/houschka_rounded_extra_bold-webfont.woff2') format('woff2'),
         url('../fonts/houschka_rounded_extra_bold-webfont.woff') format('woff'),
         url('../fonts/houschka_rounded_extra_bold-webfont.ttf') format('truetype'),
         url('../fonts/houschka_rounded_extra_bold-webfont.svg#houschka_roundedextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'houschka_roundedlight';
    src: url('../fonts/houschka_rounded_light-webfont.eot');
    src: url('../fonts/houschka_rounded_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/houschka_rounded_light-webfont.woff2') format('woff2'),
         url('../fonts/houschka_rounded_light-webfont.woff') format('woff'),
         url('../fonts/houschka_rounded_light-webfont.ttf') format('truetype'),
         url('../fonts/houschka_rounded_light-webfont.svg#houschka_roundedlight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'houschka_roundedmedium';
    src: url('../fonts/houschka_rounded_medium-webfont.eot');
    src: url('../fonts/houschka_rounded_medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/houschka_rounded_medium-webfont.woff2') format('woff2'),
         url('../fonts/houschka_rounded_medium-webfont.woff') format('woff'),
         url('../fonts/houschka_rounded_medium-webfont.ttf') format('truetype'),
         url('../fonts/houschka_rounded_medium-webfont.svg#houschka_roundedmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'houschka_prodemibold';
    src: url('../fonts/houschkapro-demibold-webfont.eot');
    src: url('../fonts/houschkapro-demibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/houschkapro-demibold-webfont.woff2') format('woff2'),
         url('../fonts/houschkapro-demibold-webfont.woff') format('woff'),
         url('../fonts/houschkapro-demibold-webfont.ttf') format('truetype'),
         url('../fonts/houschkapro-demibold-webfont.svg#houschka_prodemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 12, 2019 */
@font-face {
    font-family: 'axisbold';
    src: url('../fonts/axis-webfont.eot');
    src: url('../fonts/axis-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/axis-webfont.woff2') format('woff2'),
         url('../fonts/axis-webfont.woff') format('woff'),
         url('../fonts/axis-webfont.ttf') format('truetype'),
         url('../fonts/axis-webfont.svg#axisbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'cassannet_outlineregular';
    src: url('../fonts/cassannet_outline_0-webfont.eot');
    src: url('../fonts/cassannet_outline_0-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/cassannet_outline_0-webfont.woff2') format('woff2'),
         url('../fonts/cassannet_outline_0-webfont.woff') format('woff'),
         url('../fonts/cassannet_outline_0-webfont.ttf') format('truetype'),
         url('../fonts/cassannet_outline_0-webfont.svg#cassannet_outlineregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'noto_serifbold';
    src: url('../fonts/notoserif-bold-webfont.eot');
    src: url('../fonts/notoserif-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/notoserif-bold-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-bold-webfont.woff') format('woff'),
         url('../fonts/notoserif-bold-webfont.ttf') format('truetype'),
         url('../fonts/notoserif-bold-webfont.svg#noto_serifbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'noto_serifbold_italic';
    src: url('../fonts/notoserif-bolditalic-webfont.eot');
    src: url('../fonts/notoserif-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/notoserif-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-bolditalic-webfont.woff') format('woff'),
         url('../fonts/notoserif-bolditalic-webfont.ttf') format('truetype'),
         url('../fonts/notoserif-bolditalic-webfont.svg#noto_serifbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'noto_serifitalic';
    src: url('../fonts/notoserif-italic-webfont.eot');
    src: url('../fonts/notoserif-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/notoserif-italic-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-italic-webfont.woff') format('woff'),
         url('../fonts/notoserif-italic-webfont.ttf') format('truetype'),
         url('../fonts/notoserif-italic-webfont.svg#noto_serifitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'noto_serifregular';
    src: url('../fonts/notoserif-regular-webfont.eot');
    src: url('../fonts/notoserif-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/notoserif-regular-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-regular-webfont.woff') format('woff'),
         url('../fonts/notoserif-regular-webfont.ttf') format('truetype'),
         url('../fonts/notoserif-regular-webfont.svg#noto_serifregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'houschka_roundedlight';
$bold: 'houschka_prodemibold';
$boldE: 'houschka_roundedextrabold';
$boldD: 'houschka_prodemibold';
$medium: 'houschka_roundedmedium';
$esqadero: 'esqadero_ff_cy_4fregular';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

* {
    box-sizing: border-box;
}
*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
    -webkit-text-size-adjust: 100%;
    color: #414141;
    padding-top: 120px;
	margin: 0;
    @media screen and (max-width: 1024px) {
        padding-top: 80px;
        &.open {
            header {
                .header__block {
                    left: 0;
                    box-shadow: 50px 0px 50px rgba(0,0,0,0.5);
                }
                .close {
                    right: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        height: auto;
        display: block;
    }
}

p {
    margin: 0 0 15px;
}

h1 {
	font-family: $esqadero;
	font-size: 46px;
	text-align: center;
	color: #414141;
	line-height: 1;
	margin: 0 0 30px;
	@media screen and (max-width: 1200px) {
		font-size: 38px;
        a {
            color: #414141;
            &:hover {
                color: #fcd900;
            }
        }
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 20px;
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 15px;
    }
    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
}
h2 {
	font-family: $esqadero;
	font-size: 44px;
	color: #414141;
    margin: 0 0 30px;
	a {
		color: #414141;
        &:hover {
            color: #fcd900;
        }
	}
	@media screen and (max-width: 1200px) {
		font-size: 30px;
		a {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
        margin-bottom: 20px;
		a {
			font-size: 24px;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 15px;
        a {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 600px) {
        font-size: 18px;
        a {
            font-size: 18px;
        }
    }
}
h3 {
	font-family: $boldD;
	font-size: 26px;
	color: #414141;
	@media screen and (max-width: 1024px) {
		font-size: 22px;
        margin-bottom: 20px;
	}
    @media screen and (max-width: 768px) {
        font-size: 18px;
        margin-bottom: 15px;
    }
    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
}
h4 {
	font-family: $bold;
	font-size: 22px;
	color: #414141;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $bold;
	font-size: 18px;
	color: #414141;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #414141;
    text-transform: uppercase;
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
}
a {
    color: #414141;
	text-decoration: none;
    transition: 350ms;
	&:focus {
		color: #414141;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #fcd900;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
    font-size: 18px;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        width: 100%;
        min-height: 65px;
        background-color: #fff;
        border-radius: 0px;
        background-color: #fff;
        font-family: $regular;
        font-size: 18px;
        color: #414141;
        border: 1px solid #bababa;
        padding: 20px 20px;
        margin-bottom: 20px;
        box-shadow: none;
        line-height: 1;
        &::placeholder {
            color: #414141;
        }
	}
    @media screen and (max-width: 1280px) {
        font-size: 16px;
    }
    @media screen and (max-width: 1200px) {
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 50px;
                font-size: 16px;
                padding: 14px 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                font-size: 14px;
                padding: 10px 15px;
                margin-bottom: 15px;
            }
        }
    }
}
.main {
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}
.container {
    width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    @media screen and (max-width: 1170px) {
        width: 100%;
    }
}
.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: #fcd900;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: flex;
    height: 64px;
	width: 100%!important;
	background-color: #fcd900!important;
	font-family: $boldE!important;
	font-size: 18px!important;
	color: #000000!important;
    text-align: center;
	cursor: pointer;
	border-radius: 0px;
	border: 1px solid #fcd900;
	padding: 10px 40px!important;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
	transition: 300ms;
    line-height: 1;
    &:focus, &:active {
		background-color: #fcd900!important;
		color: #000!important;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #414141!important;
	}	
    &.white {
        background-color: #fff!important;
        color: #414141!important;
        &:hover {
            background-color: #fcd900!important;
            color: #000!important;
        }
    }
    @media screen and (max-width: 1200px) {
        height: 50px;
        font-size: 16px!important;
        padding: 8px 30px!important;
    }
    @media screen and (max-width: 1024px) {
        height: 40px;
        font-size: 14px!important;
        padding: 8px 20px!important;
    }
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 30px;
		margin: 10px 0;
		&:before {
			content: '\f1e3';
			position: absolute;
            background-color: #fcd900;
			font-family: 'fontAwesome';
			color: #000000;
			left: 0;
			top: 3px;
            border-radius: 100%;
		}
	}
    @media screen and (max-width: 1280px) {
        li {
            padding-left: 25px;
            &:before {
                top: 2px;
            }
        }
    }
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #fcd900;
		position: absolute;
		top: -1px;
		left: 0;
	}
    @media screen and (max-width: 1024px) {
        &:after {
            font-size: 16px;
            top: -1px;
        }
    }
    @media screen and (max-width: 768px) {
        padding-left: 20px;
        &:after {
            font-size: 14px;
            top: 0;
        }
    }
}
.col--location:after {
	content: '\f041';
}
.col--phone {
    &:after {
        content: '\f095';
    }
    a {
        pointer-events: none;
    }
    @media screen and (max-width: 768px) {
        a {
            pointer-events: auto;
        }
    }
}
.col--email:after {
	content: '\f0e0';
	font-size: 16px;
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
    @media screen and (max-width: 768px) {
        font-size: 12px;
        top: 1px;
    }
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
    display: none;
}

.preload {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    opacity: 1;
    pointer-events: none;
    &.load {
        opacity: 0;
        transition: 500ms;
    }
}

/* header */
header {
    height: 120px;
    background-color: #222222;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    font-family: $esqadero;
    font-size: 16px;
    color: #fff;
    line-height: 1;
    z-index: 9;

    ul {
        @include ul-default;
    }

    a {
        color: #fff;
        &:focus {
            color: #fff;
        }
    }

    .up, .down {
        display: flex;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid #353535;
        box-sizing: border-box;
    }

    .up {
        justify-content: flex-end;
        padding: 5px 50px 5px 15px;
    }

	/* header logo */
	.logo {
        display: flex;
        height: 50px;
        justify-content: left;
        align-items: center;
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 1;
        overflow: hidden;
        img {
            max-width: 100%;
        }
    }
	/* header logo */

    /* header phones */
    .col--phone {
        margin-left: 55px;
    }
    /* header phones */

    /* header email */
    .col--email {
        margin-left: 55px;
    }
    /* header email */

    /* header search */
    .search {
        display: flex;
        height: 50px;
        width: 50px;
        justify-content: flex-end;
        overflow: hidden;
        position: relative;
        transition-duration: .5s;
        input[type="search"] {
            display: flex;
            width: 100%;
            align-items: center;
            background-color: transparent;
            font-family: $regular;
            font-size: 18px;
            color: #fff;
            padding: 10px 25px;
            border: none;
            outline: none;
            line-height: 1;
            &::placeholder {
                color: #5b5b5b;
            }
        }
        button {
            height: 50px;
            width: 50px;
            flex: none;
            border: none;
            background-color: transparent;
            font-family: 'fontAwesome';
            font-size: 18px;
            color: #ffda00;
            cursor: pointer;
            padding: 0;
            border-left: 2px solid transparent; 
            transition: 300ms;
            outline: none;
            &:after {
                content: '\f002';
            }
        }
        &:before {
            content: '';
            background-color: #131313;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            z-index: -1;
            transition: 350ms;
        }
        &:hover {
            width: 55%;
            &:before {
                opacity: 1;
            }
            button {
                border-left: 2px solid #b0b0b0;
            }
        }
    }
    
    /* header search */

	/* header nav */
	.nav {
        width: 100%;
        height: 100%;
        > ul {
            display: flex;
            width: 100%;
            > li {
                display: flex;
                align-items: center;
                position: relative;
                flex-grow: 1;
                height: 100%;
                width: 12.5%;
                border-right: 1px solid #353535;
                &:last-of-type {
                    border-right: 1px solid transparent;
                }
                > a {
                    display: flex;
                    height: 60px;
                    width: 100%;
                    font-size: 15px;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    &:hover {
                        background-color: #353535;
                        color: #fff;
                    }
                }
                &.submenu {
                    > a {
                        background-color: #fcd900!important;
                        color: #353535;
                        &:hover {
                            background-color: #353535!important;
                            color: #fff;
                        }
                    }
                    span {
                        &:after {
                            content: '\f107';
                            display: inline;
                            font-family: 'fontAwesome';
                            margin-left: 5px;
                        }
                    }
                }
                &.submenu--cat {  
                    font-size: 14px;  
                    .submenu__block {
                        padding: 10px;
                        ul li {
                            margin: 0;
                            a {
                                display: block;
                                text-align: center;
                                padding: 23px 0px 13px;
                            }
                        }
                    }
                    > a {
                        background-color: transparent!important;
                        color: #fff;
                        &:hover {
                            background-color: #353535!important;
                            color: #fff;
                        }
                    }
                    &.active {
                        > a { 
                            background-color: #353535!important;
                            color: #fff;
                        }
                    }
                }
                &.active > a {
                    background-color: #353535;
                }
                .submenu__block {
                    display: none;
                    min-width: 100%;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    padding: 40px 20px 30px 20px;
                    z-index: 1;
                    &:before {
                        content: '';
                        background-color: #222222;
                        position: absolute;
                        top: 10px;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: -1;
                    }
                    ul li {
                        margin: 10px 0;

                        &.active {
                            a {
                                color: #fcd900;
                            }
                        }
                    }
                    &.submenu--cat {
                        position: fixed;
                        min-width: 0px;
                        width: 1170px;
                        right: 0;
                        left: 0;
                        top: 120px;
                        margin: 0 auto;
                        ul {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            li {
                                width: calc(50% - 10px);
                                margin: 10px 0;
                                a {
                                    display: flex;
                                    min-height: 110px;
                                    background-color: #fff;
                                    border: 5px solid transparent;
                                    font-family: $esqadero;
                                    font-size: 18px;
                                    color: #414141;
                                    padding: 10px 25px;
                                    align-items: center;
                                    justify-content: space-between;
                                    white-space: inherit;
                                    &:hover {
                                        border: 5px solid #fcd900;
                                    }
                                    img {
                                        width: 125px;
                                        height: 90px;
                                        object-fit: contain;
                                        object-position: center center;
                                        margin-left: 10px;
                                    }
                                }
                                &.active a {
                                    border: 5px solid #fcd900;
                                }
                                &.full {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
	/* header nav */

    .close {
        display: none;
    }

    @media screen and (min-width: 2000px) {
        .logo {
            width: 280px;
            left: 50%;
            margin-left: -1070px;
        }
    }

    @media screen and (min-width: 1441px) {
        .nav > ul {
            justify-content: center;
            > li {
                flex-grow: 0;
                width: 280px;
            }
        }
    }
	
    @media screen and (min-width: 1025px) {
        .up {
            position: relative;
        }
    }

    @media screen and (max-width: 1680px) {
        .nav > ul > li > a {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 1580px) {
        .search {
            &:hover {
                width: 50%; 
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .search {
            &:hover {
                width: 45%; 
            }
        }
        .col--phone, .col--email {
            margin-left: 30px;
        }
        .nav {
            > ul > li > {
                > a {
                    font-size: 14px;
                }
                .submenu__block {
                    &.submenu--cat {
                        width: auto;
                        padding: 20px 20px 15px 20px;
                        left: 15px;
                        right: 15px;
                        ul li {
                            a {
                                min-height: 50px;
                                font-size: 14px;
                                padding: 5px 15px;
                                img {
                                    height: 55px;
                                    width: 80px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .up {
            padding: 5px 20px;
        }
        .search {
            &:hover {
                width: 40%; 
            }
        }
        .logo {
            left: 0px;
        }
    }

	@media screen and (max-width: 1024px) {
        height: 80px;
        font-size: 14px;
        .header__block {
            display: flex;
            height: 100%;
            align-items: flex-start;
            flex-direction: column;
            background-color: #222;
            width: calc(100% - 50px);
            position: fixed;
            top: 0;
            left: -100%;
            right: 0;
            overflow-x: hidden;
            overflow-y: auto;
            transition: 500ms;
            z-index: 1;
        }
        .up {   
            height: auto;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            order: 2;
            flex: none;
            padding: 15px;
        }
        .down {
            height: auto;
            width: 100%;
            align-items: flex-start;
            order: 1;
            flex: none;
        }
        .logo {
            width: 160px;
            left: 0;
            right: 0;
            top: 50%;
            margin: 0 auto;
            transform: translateY(-50%);
            img {
                width: 100%;
            }
        }
        .search {
            width: 100%;
            order: 3;
            margin-top: 15px;
            &:before {
                opacity: 1;
            }
            &:hover {
                width: 100%;
            }
            button {
                border-left: 2px solid #b0b0b0;
            }
        }
        .col--phone,
        .col--email {
            margin: 10px 0;
        }
        .nav {
            padding-bottom: 20px;
            > ul {
                display: block;
                > li {
                    display: block;
                    width: 100%;
                    > a {
                        height: auto;
                        justify-content: flex-start;
                        text-align: left;
                        padding: 10px 15px;
                    }
                    .submenu__block {
                        padding: 5px 20px;
                        position: relative;
                        top: 0;
                        left: 0;
                        &.submenu--cat {
                            padding: 0 15px;
                            position: relative;
                            top: 0;
                            left: 0;
                            right: 0;
                            ul li a {
                                min-height: 0px;
                                font-size: 14px;
                                img {
                                    height: 60px;
                                    width: 80px;
                                }
                            }
                        }
                    }
                }
            }
        }
		.nav--btn {
            width: 35px;
            height: 30px;
            position: absolute;
            left: 12px;
            top: 50%;
            padding: 0;
            margin: 0;
            outline: none;
            float: none;
            transform: translateY(-50%);
            span {
                position: absolute;
                left: 0;
                right: 0;
                width: 85%;
                height: 3px;
                margin: 0 auto;
                background-color: #fcd900;
                transition: 350ms;
                &:nth-of-type(1) {
                    top: 7px;
                }
                &:nth-of-type(2) {
                    top: 14px;
                }
                &:nth-of-type(3) {
                    top: 21px;
                }
            }
            &.open {
                span {
                    &:nth-of-type(1) {
                        top: 14px;
                        transform: rotate(45deg);
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                    &:nth-of-type(3) {
                        top: 14px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        .close {
            display: block;
            position: fixed;
            right: 100%;
            top: 10px;
            z-index: 1;
            transition: 500ms;
        }
	}
    @media screen and (max-width: 600px) {
        .logo {
            width: 160px;
            height: 40px;
        }
        .nav > ul > li .submenu__block.submenu--cat {
            padding: 5px 15px;
            ul li {
                width: 100%;
                min-height: 30px;
                margin: 5px 0px;
                a {
                    font-size: 12px;
                    img {
                        height: 40px;
                        width: 60px;
                    }
                }
            }
        }
    }
}
/* header */